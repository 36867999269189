$button-primary: rgba(43, 43, 43, 1);
$button-primary-hover: rgba(5, 5, 5, 1);

button[class^=btn]{
  border:0;
  outline:none;
  padding:2px;
}

*[class^=btn],
.btn,
button[class^=btn]{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $button-primary;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s;
  margin: 8px 0;
  padding: 4px;
  box-sizing: border-box;
  transition: all 0.25s;
  //box-shadow:0 0 4px rgba(0,0,0,0) !important;

  &.noMargin {
    margin: 3px !important;
  }

  &:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 45%;
    width: 10%;
    height: 10%;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.25s;
    pointer-events: none;
  }
}

button[class^=btn]{
  height:44px;
  width:44px;
  max-width:44px;
  min-width:44px;
  font-size:11px;
}

.l-tab.btn:after,
button[class^=btn],
button[class^=btn]:after {
  border-radius: 50%;
}

.info-window-btn:hover,
.btn:hover,
button[class^=btn]:hover{
  //box-shadow:0 0 3px rgba(0,0,0,1) !important;
}

.info-window-btn:not(.disabled):hover:after,
.btn:not(.disabled):hover:after,
button[class^=btn]:not(.disabled):hover:after{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}

.l-tab {
  &.btn:not(.disabled):hover:after {
    border-radius: 50%;
  }
}

.accordion-body {
  .btn-group {
    width: 80%;
    margin-bottom: 12px;

    .btn {
      height: 22px;
      font-size: 12px;
      width: auto;
    }
  }
}

.circle-btn{
  position:relative;
  border:0;
  letter-spacing: 0.4px;
  width:40px;
  max-width:40px;
  height:40px;
  margin:4px;
  background-color:#fff;
  border-radius:50%;
  color:#000 !important;
  font-size:10px;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  //box-shadow: 0 0 3px rgba(0,0,0,0);
  transition: all 0.25s;

  &:hover{
    //box-shadow: 0 0 8px rgba(0,0,0,0.95);
  }

  &:after {
    content: '';
    position: absolute;
    border-radius:50%;
    top: 45%;
    left: 45%;
    width: 10%;
    height: 10%;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,0) 25%, rgba(171,171,171,1) 100%);
    opacity:0;
    transition: all 0.25s;
  }

  &:not(.disabled):hover:after{
    opacity:1;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
    //border:2px solid rgba(255,255,255,0.7);
  }
}

.r-dlg-btn{
  background-color:#131313 !important;
  width:52px !important;
  height:52px !important;
  max-width:52px !important;
  max-height:52px !important;
  font-size:10px;
}

.black{
  background-color:rgba(13,13,13,1);
}

.disabled{
  background-color:rgba(190,190,190,1);
}